// application.fonts.css is created using Google Webfonts Helper
// (https://google-webfonts-helper.herokuapp.com/fonts)
import './content/assets/css/application.fonts.css';

// require ('@fortawesome/fontawesome-pro/css/all.min.css');
require ('@fortawesome/fontawesome-pro/css/duotone.min.css');
require ('@fortawesome/fontawesome-pro/css/light.min.css');
require ('@fortawesome/fontawesome-pro/css/regular.min.css');
require ('@fortawesome/fontawesome-pro/css/solid.min.css');
require ('@fortawesome/fontawesome-pro/css/svg-with-js.min.css');

import { library, dom } from "@fortawesome/fontawesome-svg-core";
import * as FontAwesome from '@fortawesome/fontawesome-svg-core';

/*
 * You will see an icon imported more than once with an alias when we use more
 * than one version (Light, Solid, Duotone, etc) in the code. We have to use an
 * alias because WebPack will complain we've imported the same name more than
 * once.
 *
 * As it is, the name itself doesn't matter - we could call them Duck, Horse,
 * and Cow - the code refers to them by their Font Awesome class names.
 *
*/

/*
 * IMPORTANT
 *
 * Don't forget to add a "library.add(...)" below when you add an icon to this list!
 *
 * IMPORTANT
*/
import {/* webpackPrefetch: true */ faArrowDown as faArrowDownRegular} from "@fortawesome/pro-regular-svg-icons";
import {/* webpackPrefetch: true */ faArrowDown as faArrowDownSolid} from "@fortawesome/pro-solid-svg-icons";
import {/* webpackPrefetch: true */ faArrowLeft as faArrowLeftDuoTone} from "@fortawesome/pro-duotone-svg-icons";
import {/* webpackPrefetch: true */ faArrowLeft as faArrowLeftLight} from "@fortawesome/pro-light-svg-icons";
import {/* webpackPrefetch: true */ faArrowRight as faArrowRightDuoTone} from "@fortawesome/pro-duotone-svg-icons";
import {/* webpackPrefetch: true */ faArrowRight as faArrowRightLight} from "@fortawesome/pro-light-svg-icons";
import {/* webpackPrefetch: true */ faArrowUp as faArrowUpRegular} from "@fortawesome/pro-regular-svg-icons";
import {/* webpackPrefetch: true */ faArrowUp as faArrowUpSolid} from "@fortawesome/pro-solid-svg-icons";
import {/* webpackPrefetch: true */ faBan as faBanLight} from "@fortawesome/pro-light-svg-icons";
import {/* webpackPrefetch: true */ faBan as faBanRegular } from "@fortawesome/pro-regular-svg-icons";
import {/* webpackPrefetch: true */ faBan as faBanSolid } from "@fortawesome/pro-solid-svg-icons";
import {/* webpackPrefetch: true */ faBookOpen as faBookOpenDuoTone } from "@fortawesome/pro-duotone-svg-icons";
import {/* webpackPrefetch: true */ faBookReader as faBookReaderDuoTone } from "@fortawesome/pro-duotone-svg-icons";
import {/* webpackPrefetch: true */ faBox as faBoxDuoTone } from "@fortawesome/pro-duotone-svg-icons";
import {/* webpackPrefetch: true */ faBox as faBoxLight } from "@fortawesome/pro-light-svg-icons";
import {/* webpackPrefetch: true */ faBoxOpen as faBoxOpenDuoTone } from "@fortawesome/pro-duotone-svg-icons";
import {/* webpackPrefetch: true */ faCalendar as faCalendarRegular } from "@fortawesome/pro-regular-svg-icons";
import {/* webpackPrefetch: true */ faCalendarAlt as faCalendarAltRegular } from "@fortawesome/pro-regular-svg-icons";
import {/* webpackPrefetch: true */ faCalendarCheck as faCalendarCheckRegular } from "@fortawesome/pro-regular-svg-icons";
import {/* webpackPrefetch: true */ faCameraMovie as faCameraMovieDuoTone } from "@fortawesome/pro-duotone-svg-icons";
import {/* webpackPrefetch: true */ faCameraMovie as faCameraMovieLight } from "@fortawesome/pro-light-svg-icons";
import {/* webpackPrefetch: true */ faCameraMovie as faCameraMovieSolid } from "@fortawesome/pro-solid-svg-icons";
import {/* webpackPrefetch: true */ faCheck as faCheckDuotone } from "@fortawesome/pro-duotone-svg-icons";
import {/* webpackPrefetch: true */ faCheck as faCheckRegular } from "@fortawesome/pro-regular-svg-icons";
import {/* webpackPrefetch: true */ faChevronLeft as faChevronLeftRegular } from "@fortawesome/pro-regular-svg-icons";
import {/* webpackPrefetch: true */ faChevronLeft as faChevronLeftSolid } from "@fortawesome/pro-solid-svg-icons";
import {/* webpackPrefetch: true */ faChevronRight as faChevronRightRegular } from "@fortawesome/pro-regular-svg-icons";
import {/* webpackPrefetch: true */ faChevronRight as faChevronRightSolid } from "@fortawesome/pro-solid-svg-icons";
import {/* webpackPrefetch: true */ faCircle as faCircleDuoTone } from "@fortawesome/pro-duotone-svg-icons";
import {/* webpackPrefetch: true */ faCircle as faCircleLight } from "@fortawesome/pro-light-svg-icons";
import {/* webpackPrefetch: true */ faCircle as faCircleSolid } from "@fortawesome/pro-solid-svg-icons";
import {/* webpackPrefetch: true */ faCircle} from "@fortawesome/pro-regular-svg-icons";
import {/* webpackPrefetch: true */ faClock as faClockRegular } from "@fortawesome/pro-regular-svg-icons";
import {/* webpackPrefetch: true */ faClone as faCloneDuoTone } from "@fortawesome/pro-duotone-svg-icons";
import {/* webpackPrefetch: true */ faComment as faCommentDuoTone } from "@fortawesome/pro-duotone-svg-icons";
import {/* webpackPrefetch: true */ faComment as faCommentLight } from "@fortawesome/pro-light-svg-icons";
import {/* webpackPrefetch: true */ faComments as faCommentsDuoTone } from "@fortawesome/pro-duotone-svg-icons";
import {/* webpackPrefetch: true */ faComments as faCommentsLight } from "@fortawesome/pro-light-svg-icons";
import {/* webpackPrefetch: true */ faComments as faCommentsSolid } from "@fortawesome/pro-solid-svg-icons";
import {/* webpackPrefetch: true */ faCompress as faCompressDuoTone } from "@fortawesome/pro-duotone-svg-icons";
import {/* webpackPrefetch: true */ faCompressArrowsAlt as faCompressArrowsAltDuoTone } from "@fortawesome/pro-duotone-svg-icons";
import {/* webpackPrefetch: true */ faCompressArrowsAlt as faCompressArrowsAltLight} from "@fortawesome/pro-light-svg-icons";
import {/* webpackPrefetch: true */ faCopy as faCopyLight} from "@fortawesome/pro-light-svg-icons";
import {/* webpackPrefetch: true */ faCopy } from "@fortawesome/pro-light-svg-icons";
import {/* webpackPrefetch: true */ faScissors as faScissorsLight} from "@fortawesome/pro-light-svg-icons";
import {/* webpackPrefetch: true */ faScissors as faScissorsRegular} from "@fortawesome/pro-regular-svg-icons";
import {/* webpackPrefetch: true */ faDownload  as faDownloadDuoTone} from "@fortawesome/pro-duotone-svg-icons";
import {/* webpackPrefetch: true */ faDownload  as faDownloadLight} from "@fortawesome/pro-light-svg-icons";
import {/* webpackPrefetch: true */ faEdit } from "@fortawesome/pro-duotone-svg-icons";
import {/* webpackPrefetch: true */ faExpandWide as faExpandWideDuoTone } from "@fortawesome/pro-duotone-svg-icons";
import {/* webpackPrefetch: true */ faExpandWide as faExpandWideLight } from "@fortawesome/pro-light-svg-icons";
import {/* webpackPrefetch: true */ faFilePdf as faFilePdfDuoTone } from "@fortawesome/pro-duotone-svg-icons";
import {/* webpackPrefetch: true */ faFilePdf as faFilePdfLight } from "@fortawesome/pro-light-svg-icons";
import {/* webpackPrefetch: true */ faFilePdf as faFilePdfSolid } from "@fortawesome/pro-solid-svg-icons";
import {/* webpackPrefetch: true */ faImagePolaroid as faImagePolaroidDuoTone } from "@fortawesome/pro-duotone-svg-icons";
import {/* webpackPrefetch: true */ faImagePolaroid as faImagePolaroidLight } from "@fortawesome/pro-light-svg-icons";
import {/* webpackPrefetch: true */ faImagePolaroid as faImagePolaroidSolid } from "@fortawesome/pro-solid-svg-icons";
import {/* webpackPrefetch: true */ faImages as faImagesDuotone } from "@fortawesome/pro-duotone-svg-icons";
import {/* webpackPrefetch: true */ faImages as faImagesSolid } from "@fortawesome/pro-solid-svg-icons";
import {/* webpackPrefetch: true */ faInfoCircle } from "@fortawesome/pro-duotone-svg-icons";
import {/* webpackPrefetch: true */ faMicrophoneAlt } from "@fortawesome/pro-duotone-svg-icons";
import {/* webpackPrefetch: true */ faPauseCircle } from "@fortawesome/pro-duotone-svg-icons";
import {/* webpackPrefetch: true */ faPhotoVideo as faPhotoVideoDuoTone } from "@fortawesome/pro-duotone-svg-icons";
import {/* webpackPrefetch: true */ faPhotoVideo as faPhotoVideoLight } from "@fortawesome/pro-light-svg-icons";
import {/* webpackPrefetch: true */ faPhotoVideo as faPhotoVideoSolid } from "@fortawesome/pro-solid-svg-icons";
import {/* webpackPrefetch: true */ faPlay } from "@fortawesome/pro-duotone-svg-icons";
import {/* webpackPrefetch: true */ faPlus as faPlusDuoTone } from "@fortawesome/pro-duotone-svg-icons";
import {/* webpackPrefetch: true */ faPlus as faPlusLight } from "@fortawesome/pro-light-svg-icons";
import {/* webpackPrefetch: true */ faPlus as faPlusRegular } from "@fortawesome/pro-regular-svg-icons";
import {/* webpackPrefetch: true */ faPlus as faPlusSolid } from "@fortawesome/pro-solid-svg-icons";
import {/* webpackPrefetch: true */ faProjector } from "@fortawesome/pro-duotone-svg-icons";
import {/* webpackPrefetch: true */ faQuestion as faQuestionDuoTone } from "@fortawesome/pro-duotone-svg-icons";
import {/* webpackPrefetch: true */ faRainbow } from "@fortawesome/pro-duotone-svg-icons";
import {/* webpackPrefetch: true */ faRedo } from "@fortawesome/pro-light-svg-icons";
import {/* webpackPrefetch: true */ faSave as faSaveDuoTone } from "@fortawesome/pro-duotone-svg-icons";
import {/* webpackPrefetch: true */ faSearchMinus as faSearchMinusDuoTone } from "@fortawesome/pro-duotone-svg-icons";
import {/* webpackPrefetch: true */ faSearchMinus as faSearchMinusLight } from "@fortawesome/pro-light-svg-icons";
import {/* webpackPrefetch: true */ faSearchPlus as faSearchPlusLight } from "@fortawesome/pro-light-svg-icons";
import {/* webpackPrefetch: true */ faSmileBeam as faSmileBeamDuoTone } from "@fortawesome/pro-duotone-svg-icons";
import {/* webpackPrefetch: true */ faSortAmountDown as faSortAmountDownDuoTone} from "@fortawesome/pro-duotone-svg-icons";
import {/* webpackPrefetch: true */ faSortAmountDown as faSortAmountDownSolid} from "@fortawesome/pro-solid-svg-icons";
import {/* webpackPrefetch: true */ faSortAmountDownAlt as faSortAmountDownAltDuoTone} from "@fortawesome/pro-duotone-svg-icons";
import {/* webpackPrefetch: true */ faSortAmountDownAlt as faSortAmountDownAltSolid} from "@fortawesome/pro-solid-svg-icons";
import {/* webpackPrefetch: true */ faSortAmountUp as faSortAmountUpAltSolid} from "@fortawesome/pro-solid-svg-icons";
import {/* webpackPrefetch: true */ faSortAmountUp as faSortAmountUpDuoTone} from "@fortawesome/pro-duotone-svg-icons";
import {/* webpackPrefetch: true */ faSortAmountUp as faSortAmountUpSolid} from "@fortawesome/pro-solid-svg-icons";
import {/* webpackPrefetch: true */ faSpinner as faSpinnerDuoTone } from "@fortawesome/pro-duotone-svg-icons";
import {/* webpackPrefetch: true */ faSquareFull } from "@fortawesome/pro-duotone-svg-icons";
import {/* webpackPrefetch: true */ faSquare as faSquareSolid } from "@fortawesome/pro-solid-svg-icons";
import {/* webpackPrefetch: true */ faStar as faStarDuoTone } from "@fortawesome/pro-duotone-svg-icons";
import {/* webpackPrefetch: true */ faStar as faStarLight } from "@fortawesome/pro-light-svg-icons";
import {/* webpackPrefetch: true */ faStar as faStarRegular } from "@fortawesome/pro-regular-svg-icons";
import {/* webpackPrefetch: true */ faStar as faStarSolid } from "@fortawesome/pro-solid-svg-icons";
import {/* webpackPrefetch: true */ faText as faTextDuoTone } from "@fortawesome/pro-duotone-svg-icons";
import {/* webpackPrefetch: true */ faText as faTextSolid } from "@fortawesome/pro-solid-svg-icons";
import {/* webpackPrefetch: true */ faTh } from "@fortawesome/pro-duotone-svg-icons";
import {/* webpackPrefetch: true */ faTimes as faTimesRegular } from "@fortawesome/pro-regular-svg-icons";
import {/* webpackPrefetch: true */ faTimesCircle } from "@fortawesome/pro-duotone-svg-icons";
import {/* webpackPrefetch: true */ faTrash as faTrashDuoTone } from "@fortawesome/pro-duotone-svg-icons";
import {/* webpackPrefetch: true */ faTrash as faTrashLight } from "@fortawesome/pro-light-svg-icons";
import {/* webpackPrefetch: true */ faTrash as faTrashRegular } from "@fortawesome/pro-regular-svg-icons";
import {/* webpackPrefetch: true */ faTrash as faTrashSolid } from "@fortawesome/pro-solid-svg-icons";
import {/* webpackPrefetch: true */ faTrashAlt as faTrashAltDuoTone } from "@fortawesome/pro-duotone-svg-icons";
import {/* webpackPrefetch: true */ faTrashAlt as faTrashAltLight } from "@fortawesome/pro-light-svg-icons";
import {/* webpackPrefetch: true */ faTrashAlt as faTrashAltRegular } from "@fortawesome/pro-regular-svg-icons";
import {/* webpackPrefetch: true */ faTrashAlt as faTrashAltSolid } from "@fortawesome/pro-solid-svg-icons";
import {/* webpackPrefetch: true */ faTrashUndo as faTrashUndoDuoTone } from "@fortawesome/pro-duotone-svg-icons";
import {/* webpackPrefetch: true */ faTrashUndo as faTrashUndoLight } from "@fortawesome/pro-light-svg-icons";
import {/* webpackPrefetch: true */ faTrashUndo as faTrashUndoRegular } from "@fortawesome/pro-regular-svg-icons";
import {/* webpackPrefetch: true */ faTrashUndo as faTrashUndoSolid } from "@fortawesome/pro-solid-svg-icons";
import {/* webpackPrefetch: true */ faUndo as faUndoDuoTone } from "@fortawesome/pro-duotone-svg-icons";
import {/* webpackPrefetch: true */ faUndo } from "@fortawesome/pro-light-svg-icons";
import {/* webpackPrefetch: true */ faUsersClass as faUsersClassDuoTone } from "@fortawesome/pro-duotone-svg-icons";
import {/* webpackPrefetch: true */ faUsersClass as faUsersClassRegular } from "@fortawesome/pro-regular-svg-icons";
import {/* webpackPrefetch: true */ faUserSecret as faUserSecretDuoTone } from "@fortawesome/pro-duotone-svg-icons";
import {/* webpackPrefetch: true */ faUserSecret as faUserSecretLight} from "@fortawesome/pro-light-svg-icons";
import {/* webpackPrefetch: true */ faVoicemail as faVoicemailDuoTone } from "@fortawesome/pro-duotone-svg-icons";
import {/* webpackPrefetch: true */ faWindowClose as faWindowCloseLight } from "@fortawesome/pro-light-svg-icons";

library.add(faArrowDownRegular);
library.add(faArrowDownSolid);
library.add(faArrowLeftDuoTone);
library.add(faArrowLeftLight);
library.add(faArrowRightDuoTone);
library.add(faArrowRightLight);
library.add(faArrowUpRegular);
library.add(faArrowUpSolid);
library.add(faBanLight);
library.add(faBanRegular);
library.add(faBanSolid);
library.add(faBookOpenDuoTone);
library.add(faBookReaderDuoTone);
library.add(faBoxDuoTone);
library.add(faBoxLight);
library.add(faBoxOpenDuoTone);
library.add(faCalendarAltRegular);
library.add(faCalendarCheckRegular);
library.add(faCalendarRegular);
library.add(faCameraMovieDuoTone);
library.add(faCameraMovieLight);
library.add(faCameraMovieSolid);
library.add(faCheckRegular);
library.add(faCheckDuotone);
library.add(faChevronLeftRegular);
library.add(faChevronLeftSolid);
library.add(faChevronRightRegular);
library.add(faChevronRightSolid);
library.add(faCircle);
library.add(faCircleDuoTone);
library.add(faCircleLight);
library.add(faCircleSolid);
library.add(faClockRegular);
library.add(faCloneDuoTone);
library.add(faCommentDuoTone);
library.add(faCommentLight);
library.add(faCommentsDuoTone);
library.add(faCommentsLight);
library.add(faCommentsSolid);
library.add(faCompressArrowsAltDuoTone);
library.add(faCompressArrowsAltLight);
library.add(faCompressDuoTone);
library.add(faCopyLight);
library.add(faScissorsLight);
library.add(faScissorsRegular);
library.add(faDownloadDuoTone);
library.add(faDownloadLight);
library.add(faEdit);
library.add(faExpandWideDuoTone);
library.add(faExpandWideLight);
library.add(faFilePdfDuoTone);
library.add(faFilePdfLight);
library.add(faFilePdfSolid);
library.add(faImagePolaroidDuoTone);
library.add(faImagePolaroidLight);
library.add(faImagePolaroidSolid);
library.add(faImagesDuotone);
library.add(faImagesSolid);
library.add(faInfoCircle);
library.add(faMicrophoneAlt);
library.add(faPauseCircle);
library.add(faPhotoVideoDuoTone);
library.add(faPhotoVideoLight);
library.add(faPhotoVideoSolid);
library.add(faPlay);
library.add(faPlusDuoTone)
library.add(faPlusLight)
library.add(faPlusRegular)
library.add(faPlusSolid)
library.add(faProjector);
library.add(faQuestionDuoTone);
library.add(faRainbow);
library.add(faRedo);
library.add(faSaveDuoTone);
library.add(faSearchMinusDuoTone);
library.add(faSearchMinusLight);
library.add(faSearchPlusLight);
library.add(faSmileBeamDuoTone);
library.add(faSortAmountDownAltDuoTone);
library.add(faSortAmountDownAltSolid);
library.add(faSortAmountDownDuoTone);
library.add(faSortAmountDownSolid);
library.add(faSortAmountUpAltSolid);
library.add(faSortAmountUpDuoTone);
library.add(faSortAmountUpSolid);
library.add(faSpinnerDuoTone);
library.add(faSquareFull);
library.add(faSquareSolid);
library.add(faStarDuoTone);
library.add(faStarLight);
library.add(faStarRegular);
library.add(faStarSolid);
library.add(faTh);
library.add(faTextDuoTone);
library.add(faTextSolid);
library.add(faTimesCircle);
library.add(faTimesRegular);
library.add(faTrashAltDuoTone);
library.add(faTrashAltLight);
library.add(faTrashAltRegular);
library.add(faTrashAltSolid);
library.add(faTrashDuoTone);
library.add(faTrashUndoDuoTone);
library.add(faTrashUndoLight);
library.add(faTrashUndoRegular);
library.add(faTrashUndoSolid);
library.add(faUndo);
library.add(faUndoDuoTone);
library.add(faUsersClassDuoTone);
library.add(faUsersClassRegular);
library.add(faUserSecretDuoTone);
library.add(faUserSecretLight);
library.add(faVoicemailDuoTone);
library.add(faWindowCloseLight);

dom.watch({
  onAddition: (node) => {
    try {
      console.log('FA Icon added successfully:', node.dataset.prefix, node.dataset.icon);
    } catch (e) {
      console.error('FA Icon failed:', node, e);
    }
  }
});
window.FontAwesome = FontAwesome;